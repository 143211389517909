var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

var Point = function () {
  /**
   *
   * @param {number} x
   * @param {number} y
   */
  function Point(x, y) {
    _classCallCheck(this, Point);

    this.x = x;
    this.y = y;
  }
  /**
   * Update the x and y values
   *
   * @param {Point} point
   */


  _createClass(Point, [{
    key: "update",
    value: function update(point) {
      this.x = point.x;
      this.y = point.y;
    }
    /**
     * Get the difference for x and y axis to another point
     *
     * @param {Point} point
     * @returns {Point}
     */

  }, {
    key: "getDifferenceTo",
    value: function getDifferenceTo(point) {
      return new Point(this.x - point.x, this.y - point.y);
    }
    /**
     * Calculate distance to another point
     *
     * @param {Point} point
     * @returns {Point}
     */

  }, {
    key: "getDistanceTo",
    value: function getDistanceTo(point) {
      var diff = this.getDifferenceTo(point);
      return Math.sqrt(Math.pow(diff.x, 2) + Math.pow(diff.y, 2));
    }
  }]);

  return Point;
}();

exports.default = Point;
export default exports;