import _Catenary3 from "./Catenary";
import _Point3 from "./Point";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Point = exports.Catenary = undefined;
var _Catenary = _Catenary3;

var _Catenary2 = _interopRequireDefault(_Catenary);

var _Point = _Point3;

var _Point2 = _interopRequireDefault(_Point);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports.Catenary = _Catenary2.default;
exports.Point = _Point2.default;
export default exports;
export const __esModule = exports.__esModule,
      Point = exports.Point,
      Catenary = exports.Catenary;